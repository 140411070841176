import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logoDark from './logowhite.png';
import React, { useContext, useState } from 'react';
function CollapsibleExample() {
  return (
    <Navbar collapseOnSelect expand="lg"  variant="custom" className="container-fluid">
      <Container fluid>
        <Navbar.Brand href="/">
        <img src={logoDark} alt="Nuva Compare" height="50px"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="container-fluid">
          <Nav className="ms-auto">
          <NavDropdown title="Cryptocurrencies" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/cryptocurrencies">Cryptocurrencies</NavDropdown.Item>
              <NavDropdown.Item href="/exchanges">Exchanges</NavDropdown.Item>
              <NavDropdown.Item href="/categories">Categories</NavDropdown.Item>
              <NavDropdown.Item href="/globalchart">Global Charts</NavDropdown.Item>
        </NavDropdown>
        <Nav.Link href="/commodities">Commodities</Nav.Link>
            {/* <NavDropdown title="NFT" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Overall NFT Stats</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Top Collections</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Upcoming Sales</NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link href="/feeds">Feed</Nav.Link>
           
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;