import React from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import { useGetCryptoNewsQuery } from '../services/cryptoNewsApi';
import Loader from './Loader';
import moment from 'moment';
const demoImage = 'https://coinrevolution.com/wp-content/uploads/2020/06/cryptonews.jpg';
export default () => {

    const {data,isFetching } = useGetCryptoNewsQuery('', 10);
    console.log(data);
  const [sliderRef] = useKeenSlider(
    {
      loop: true,
    },
    [
      (slider) => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 2000)
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on("dragStarted", clearNextTimeout)
        slider.on("animationEnded", nextTimeout)
        slider.on("updated", nextTimeout)
      },
    ]
  )

  return (
    <>
     { !isFetching?
      <div ref={sliderRef} className="keen-slider">
      { data?.Data ?data.Data.slice(0,3).map((news, i) => (
         <div className={"keen-slider__slide number-slide"+i}>
            <div className="card" key={i}>
                <div className="col-md-12" style={{float:"left"}}>
                    <a href={ news.url } target="_blank" rel="noreferrer" style={{float:"left",marginRight:"10px"}}>
                        <img src={ news.imageurl || demoImage } alt="News " style={{width:"120px"}}/>
                    </a>    
                    <h6 className="news-title">{ news.title }</h6>
                    <span style={{ float: 'right' }}>{ moment(moment.unix(news.published_on).format("MM/DD/YYYY")).startOf('ss').fromNow()}</span>
                </div> 
            </div>
        </div>
        )) : <Loader/> }  
      </div>:<Loader/>
}
    </>
  )
}
