import React from 'react'
import logoDark from './logo.png';
const Footer = () => {
  return (
    <>
    <div className='row container-fluid footer'>
      <div className='col-md-6 footer-one'>
        <img src={logoDark} alt="Nuva Compare" height="150px"/>
        <p className='address-text'>Address: The Lorem ipsum text is derived from sections 1.10.32 and 1.10.33 of Cicero's 'De finibus bonorum et malorum'</p>
        <p className='copyright'>Nuva Compare 2023 &copy;all rights reserved</p>
      </div>
      <div className='col-md-6 footer-nav footer-two'>
        <div className='col-md-3'>
           <p className='heading'>Products</p>
           <ul>
            <li><a>Blockchain Explorer</a></li>
            <li><a>Crypto API </a></li>
            <li><a>Crypto Indices</a></li>
            <li><a>Jobs Board</a></li>
           </ul>
        </div>
        <div className='col-md-3'>
          <p className='heading'>Company</p>
          <ul>
            <li><a>About us</a></li>
            <li><a>Terms of use</a></li>
            <li><a>Privacy Policy</a></li>
            <li><a>Disclaimer</a></li>
            <li><a>Careers</a></li>
           </ul>
        </div>
        <div className='col-md-3'>
        <p className='heading'>Support</p>
        <ul>
            <li><a href='/adsmanagaement'>Request Form</a></li>
            <li><a>Contact Support</a></li>
            <li><a>FAQ</a></li>
           </ul>
        </div>
        <div className='col-md-3'>
           <p className='heading'>Social</p>
           <ul>
            <li><a>Facebook</a></li>
            <li><a>Twitter</a></li>
            <li><a>Telegram</a></li>
            <li><a>Instagram</a></li>
           </ul>
        </div>
     </div>
    </div>
    </>
  )
}

export default Footer