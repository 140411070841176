import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import AllStats from '../component/AllStats';
import CoinStatistics from '../component/CoinStatistics';
import ComparisonTable from '../component/ComparisonTable';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Ads from '../component/Advertise';
import Highlight from '../component/Highlight';
import Exchanges from '../component/Exchanges';
const Home = () => {
  const fullWidth = useSelector((state) => state.toggleWidth.value);
    return (
        <>
        <Highlight/>
        <AllStats/>
        <Ads/>
        <Exchanges/>
        <div className='container-fluid'>
        <Row>
          <Col className="col-sm-12" style={{ marginBottom: '20px' }}>
          <ComparisonTable simplified={ true }/>
        </Col>
        </Row>
        <CoinStatistics/>
        </div>
        </>
    )
  }
  
  export default Home