import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FormThemeProvider } from 'react-form-component'

import Form, {
  Input,
  Text,
  SubmitButton,
  FormActions,
  inlineLabel
} from 'react-form-component'
import fullWidth from '../services/fullWidth';

const LoginForm = () => {
    return (
        <>
        <div className='container row'>
            <div className='col-md-12'>
            <FormThemeProvider>
            <Form
                fields={[
                    'email',
                    'password',
                ]}
                mandatory={[
                    'email',
                    'password'
                ]}
                >
                <Input
                    label="Email Address"
                    name="email"
                />

                <Input
                    label="Password"
                    name="password"
                />
                <FormActions>
                    <SubmitButton>
                    Log In
                    </SubmitButton>
                 </FormActions>
                </Form>
            </FormThemeProvider>
            </div>
        </div>
        </>
    )
  }
  
  export default LoginForm