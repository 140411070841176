import React,{ useState, useEffect } from 'react';
import { Sparklines,SparklinesLine } from 'react-sparklines';
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableContainer,Paper
} from '@mui/material';
const useStyles = makeStyles({
    table: {
      minWidth: 650
    },
    sticky: {
      position: "sticky",
      left: 0,
      background: "white",
      boxShadow: "5px 2px 5px grey",
      borderRight: "2px solid black"
    }
  });
const Passengers = () => {
  
const classes = useStyles();
  const [passengersList, setPassengersList] = useState([]);
  const [passengersCount, setPassengersCount] = useState(0);
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10
  });
  // https://commodities-api.com/api/latest?access_key=0v0pxrd8xlsyl6z6ewujp1jl9s4968e0y5f0ktjvgg1e1pjy8jraf0p4vgeq&base=USD
  useEffect(() => {
    const getData = async () => {
       try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
          method: 'GET',
          headers: myHeaders
        };
        const response = await fetch("https://api-nuvacompare.sparklerpro.com/api/test/commodities?category=industrial", requestOptions);
      
         const result = await response.json();
          setPassengersList(result);
          setPassengersCount(Object.keys(result).length);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [controller]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };
  const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <Card>
<TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Name
            </TableCell>
            <TableCell>
              Price
            </TableCell>
            <TableCell>
              Unit
            </TableCell>
            <TableCell>
              Day Change
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {passengersList.map((passenger) => (
            <TableRow key={passenger.name}>
              <TableCell>
              {capitalizeFirst(passenger.name)}
              </TableCell>
              <TableCell>
                {passenger.price}
              </TableCell>
              <TableCell>
                {passenger.unit}
              </TableCell>
              <TableCell>
                {passenger.daychange}
              </TableCell>
            </TableRow>
          ))
        }
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        onPageChange={handlePageChange}
        page={controller.page}
        count={passengersCount}
        rowsPerPage={controller.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </TableContainer>
    </Card>
  )
}

export default Passengers;