import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import InvoiceList from '../component/InvoiceList';
import InvoiceListPaid from '../component/InvoiceListPaid';
import InvoiceListPending from '../component/InvoiceListPending';
import InvoiceListOverdue from '../component/InvoiceListOverdue';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs'
const Cryptocurrencies = () => {
  const fullWidth = useSelector((state) => state.toggleWidth.value);
    return (
        <>
        <div className='col-md-12'>
        <p className='invoice-heading'>Invoice</p>
        <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="mb-3 invice-tab"
        >
        <Tab eventKey="home" title="All" tabClassName=''>
            <InvoiceList/>
        </Tab>
        <Tab eventKey="profile" title="Paid" tabClassName=''>
        <InvoiceListPaid/>
        </Tab>
        <Tab eventKey="contact" title="Pending" tabClassName=''>
        <InvoiceListPending/>
        </Tab>
        <Tab eventKey="industrial" title="Overdue" className=''>
        <InvoiceListOverdue/>
        </Tab>
        </Tabs>
        </div>
        
        </>
    )
  }
  
export default Cryptocurrencies