import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import AllStats from '../component/AllStats';
import CategoryList from '../component/Categories';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Ads from '../component/Advertise';
const Categories = () => {
  const fullWidth = useSelector((state) => state.toggleWidth.value);
    return (
        <>
        <div className='container-fluid'>
        <CategoryList/>
        <Ads/>
        </div>
        </>
    )
  }
  
  export default Categories