import React, { useContext, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Card, Modal, Button } from 'react-bootstrap';
import millify from 'millify';
import Loader from './Loader';
import { CryptoContext } from '../contexts/CryptoContext';
import { useGetExchangesQuery } from '../services/cryptoApi';
import ExchangeCoin from "./ExchangeCoin";
const Exchanges = () => {
    
    const { globalStats, isFetching } = useContext(CryptoContext);
    const {data, isFetchingData}  = useGetExchangesQuery('Qwsogvtv82FCd');
    const showExchanges = ['Binance', 'Coinbase Pro', 'FTX', 'Kraken', 'Kucoin', 'Huobi Global', 'Gate.io','Crypto.com'];
    const showExchangesSecond = [ 'Bitfinex', 'Bitstamp', 'OKX', 'BitMart','Coinone','WazirX','LiteBit','Gemini','Bitforex'];
    const filteredExchangeList = data?.data?.exchanges?.filter(val => (showExchanges.includes(val.name)));
    const filteredExchangeListSecond = data?.data?.exchanges?.filter(val => (showExchangesSecond.includes(val.name)));
    
    const [show, setShow] = useState(false);
    const [coin, setCoin] = useState(false);
    const [name, setCoinName] = useState(false);
    const [url, setCoinUrl] = useState(false);

     //const handleShow = (coin, url, name) => setShow(true);
     function handleShow(coin, name, url) {
        console.log(coin)
        setCoin(coin);
        setCoinName(name);
        setCoinUrl(url);
        setShow(true);
      }
     const handleClose = () => setShow(false);
     const coinList = {
        Bitcoin: { uuid: 'Qwsogvtv82FCd', name: 'Bitcoin', ticker: 'BTC', iconUrl: 'https://cdn.coinranking.com/bOabBYkcX/bitcoin_btc.svg' } ,
        Ethereum: { uuid: 'razxDUgYGNAdQ', name: 'Ethereum', ticker: 'ETH', iconUrl: 'https://cdn.coinranking.com/rk4RKHOuW/eth.svg' },
        Tether: { uuid: 'HIVsRcGKkPFtW', name: 'Tether USD', ticker: 'USDT', iconUrl: 'https://cdn.coinranking.com/mgHqwlCLj/usdt.svg' },
        BinanceCoin: { uuid: 'WcwrkfNI4FUAe', name: 'Binance Coin', ticker: 'BNB', iconUrl: 'https://cdn.coinranking.com/B1N19L_dZ/bnb.svg'},
        Xrp: { uuid: '-l8Mn2pVlRs-p', name: 'Xrp', ticker: 'XRP', iconUrl: 'https://cdn.coinranking.com/B1oPuTyfX/xrp.svg'},
        Hex: { uuid: '9K7m6ufraZ6gh', name: 'Hex', ticker: 'HEX', iconUrl: 'https://cdn.coinranking.com/iseN4Am58/hex-vector.svg'},
        Cardano: { uuid: 'qzawljRxB5bYu', name: 'Cardano', ticker: 'ADA', iconUrl: 'https://cdn.coinranking.com/ryY28nXhW/ada.svg'},
        Terra: { uuid: 'AaQUAs2Mc', name: 'Terra', ticker: 'LUNA', iconUrl: 'https://cdn.coinranking.com/F-PJdF8Um/LUNA.svg'},
        Solana: { uuid: 'zNZHO_Sjf', name: 'Solana', ticker: 'SOL', iconUrl: 'https://cdn.coinranking.com/yvUG4Qex5/solana.svg'},
        Polkadot: { uuid: '25W7FG7om', name: 'Polkadot', ticker: 'DOT', iconUrl: 'https://cdn.coinranking.com/RsljYqnbu/polkadot.svg'},
        Polygon: { uuid: 'uW2tk-ILY0ii', name: 'Polygon', ticker: 'MATIC', iconUrl: 'https://cdn.coinranking.com/HAf8rW3kx/polygon-matic-rebrand.png'},
        }
     
  return (
    <div style={{padding: "20px"}} className="currencystats">
        <Row>
            <h3 className='subheading'> Bitcoin Statistics</h3>
            <Col className="col-sm-10 col-md-10 exchange">
               { filteredExchangeList?.map((exchange) => (
                <div key={ exchange.name } className="mcard text-center exchange-name" onClick={() =>handleShow(exchange.uuid,exchange.name,exchange.iconUrl)}>
                    <img src={ exchange.iconUrl } alt={ exchange.name } className="exchange-logo" />
                    <p className="exchange-name">{ exchange.name }</p>
                </div>
            )) }
           </Col>
           </Row>
           <Row style={{marginTop: "50px"}}>
           <Col className="col-sm-3 col-md-2">
           </Col>
           <Col className="col-sm-10 col-md-10 exchange">
           { filteredExchangeListSecond?.map((exchange) => (
                <Button key={ exchange.name } className="mcard text-center exchange-name" onClick={() =>handleShow(exchange.uuid,exchange.name,exchange.iconUrl)}>
                    <img src={ exchange.iconUrl } alt={ exchange.name } className="exchange-logo" />
                    <p className="exchange-name">{ exchange.name }</p>
                </Button>
            )) }
           </Col>
       </Row>

       <Modal show={show}>
        <Modal.Header closeButton  onClick={handleClose}>
          <Modal.Title className='mtitle'>
            <img src={url?url:""} className="crypto-icon-exchange"/>
             {name?name:""}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExchangeCoin exchange={coin}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close Modal</Button>
        </Modal.Footer>
      </Modal>
        
    </div>
  )
}

export default Exchanges