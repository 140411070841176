import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const cryptoApiHeaders = {
    'x-rapidapi-host': process.env.REACT_APP_RAPID_HOST,
    'x-rapidapi-key': process.env.REACT_APP_RAPID_API_KEY
}
export const cryptoApi = createApi({
    reducerPath: 'cryptoApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://coinranking1.p.rapidapi.com/' }),
    endpoints: (builder) => ({
        getCoin: builder.query({
            query: (coinUuid) => ({
                url: `coin/${coinUuid}`,
                method: 'GET',
                params: {
                    referenceCurrencyUuid: 'yhjMzLPhuIDl',
                    timePeriod: '24h'
                },
                headers: cryptoApiHeaders
            })
        }),
        getCoinHistory: builder.query({
            query: (coinUuid) => ({
                url: `coin/${coinUuid}/history`,
                method: 'GET',
                params: {
                    referenceCurrencyUuid: 'yhjMzLPhuIDl',
                    timePeriod: '24h',
                },
                headers: cryptoApiHeaders
            })
        }),
        getCryptos: builder.query({
            query: (count) => ({ 
                url: 'coins', 
                method: 'GET', 
                params:  {
                    referenceCurrencyUuid: 'yhjMzLPhuIDl', // yhjMzLPhuIDl is reference to $$$$
                    timePeriod: '24h',
                    tiers: '1',
                    orderBy: 'marketCap',
                    orderDirection: 'desc',
                    limit: count,
                    offset: '0'
                },
                headers: cryptoApiHeaders,
                })
        }),
        getExchanges: builder.query({
            query: (coinUuid) => ({
                url: `coin/${coinUuid}/exchanges/`,
                method: 'GET',
                params: {
                    referenceCurrencyUuid: 'yhjMzLPhuIDl',
                    limit: '50',
                    offset: '0',
                    orderBy: '24hVolume',
                    orderDirection: 'desc'
                },
                headers: cryptoApiHeaders,
            })
        }),
        getExchangeCoin: builder.query({
            query: (coinUuid) => ({
                url: `exchange/${coinUuid}/coins`,
                method: 'GET',
                params: {
                    referenceCurrencyUuid: 'yhjMzLPhuIDl',
                    limit: '20',
                    offset: '0',
                    orderBy: '24hVolume',
                    orderDirection: 'desc'
                },
                headers: cryptoApiHeaders,
            })
        })
    }),
});

export const { 
    useGetCryptosQuery, 
    useGetExchangesQuery, 
    useGetCoinQuery, 
    useGetCoinHistoryQuery,
    useGetExchangeCoinQuery 
} = cryptoApi