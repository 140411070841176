import React, { useContext, useState,Component,useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FormThemeProvider, Radio } from 'react-form-component'
import ReactDOM from 'react-dom'
import AuthService from "../services/auth.service"
import UserService from "../services/user.service";
import QRCode from 'react-qr-code';
//import QRCode from 'react-native-qrcode-svg';
import {useParams} from 'react-router-dom';
import btc from '../assets/btc.png';
import cal from '../assets/calendar_month.png';
const PaymentManagement = () => {
const [invoiceDetail, setInvoiceDetail] = useState([]);
const [invoiceCancle, setInvoiceCancle] = useState(false);
const [invoicePay, setInvoicePay] = useState(false);
useEffect(() => {
    const getData = async () => {
      const url = `invoicedetail/${params.id}`
      console.log(url)
      try {
        const response = await UserService.getInvoiceDetail(url);
        console.log(response.data.invoice)
        if (response.status ==200) {
            setInvoiceDetail(response.data.invoice[0]);
        } else {
          throw new Error('Request failed')
        }
      } catch (error) {
        console.log(error);
      } 
    };
    getData();
  },[]);

const handleClick = async(e) => {
    e.preventDefault();
    const url = `cancleinvoice/${params.id}`
    const response = await UserService.cancleInvoice(url);
    setInvoiceCancle(true);
  }

  const handlePay = async(e) => {
    e.preventDefault();
    const url = `payinvoice/${params.id}`
    const response = await UserService.cancleInvoice(url);
    setInvoicePay(true);
  }
  function dateformat(dateStr){
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString('en-GB');
    return formattedDate;
  }
const params = useParams();
   const size=200;
    return (
        <div className='container'>
            {invoiceDetail ? (<div className='row invoicedetail'>
            <div className='profile'>
            <div className='col-md-12 invoice-heading-new'>
              <div className='invoice-address'>
                 <p><span className='title'>Invoice : #</span><span className='text'>{invoiceDetail.id}</span></p>
                  <p><span className='title'>Name : </span> <span className='text'>{invoiceDetail.user?invoiceDetail.user.firstname:""}</span></p>
                  
              </div>
              <div className='invoice-address'>
                  <p><span className='title'>Date : </span><span className='text'> 
                  {dateformat(invoiceDetail.createdAt)}</span>
                  </p>
                  <p><span className='title'>Address : </span> <span className='text'>{invoiceDetail.user?invoiceDetail.user.companyname:""}</span></p>
              </div>
            </div>
            <div className='col-md-12 pheading'>
               <h4 className='invoice-address'>Invoice Detail</h4>
            </div>

            <div className='col-md-12 address'>
                <table className='col-md-12 itable'>
                    <tbody>
                    <tr className='col-md-12 theading'>
                        <th>ITEM</th>
                        <th>PERIOD</th>
                        <th>USD</th>
                        <th>BTC</th>
                    </tr>
                    <tr className='col-md-12 trow'>
                        <td>{invoiceDetail.packagename}</td>
                        <td><img src={cal}/> {invoiceDetail.timeperiod}</td>
                        <td>${invoiceDetail.amount}</td>
                        <td><img src={btc}/> {invoiceDetail.btc} BTC</td>
                    </tr>
                    
                    </tbody>
                </table>
                <table className='col-md-12 itablee'>
                  <tr className='col-md-12 total'>
                        <td className='col-1'></td>
                        <td className='col-1'></td>
                        <td className='ttotal col-1'><p>Total</p></td>
                        <td className='ttotal col-1'><p>${invoiceDetail.amount}</p></td>
                    </tr>
                </table>
            </div>
            <div className='col-md-12 address'>
                <table className='col-md-12'>
                   {/* <tr className='col-md-12'>
                        <td className='tright'><span className='title'>BTC Address:</span></td>
                        <td className='text-left'><span className='text'>{invoiceDetail.btcaddress}</span></td>
                    </tr> */}
                    <tr className='col-md-12 '>
                        <td className='qrcode'>
                        <QRCode
                            title="BTc"
                            value={"Address:"+invoiceDetail.btcaddress+",Amount:"+invoiceDetail.btc} 
                            bgColor="#000000"
                            fgColor="#ffffff"
                            size={size === '' ? 0 : size}
                        />
                        </td>
                    </tr>
                </table>
            </div>
            <div className='col-md-12 address iaction'>
            <p>{invoiceCancle?"Invoice cancled":""}</p>
            <p>{invoicePay?"Invoice paid":""}</p>
            <button className='btn btn-secondry' onClick={handleClick}>
            Cancle
            </button>
            <button className='btn btn-primary' onClick={handlePay}>
            Pay
            </button>
            </div>
            </div>
        </div>):"no content"
        }

        </div>
    )
  }

  
  export default PaymentManagement;