import React,{ useState, useEffect } from 'react';
import { Sparklines,SparklinesLine } from 'react-sparklines';
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableContainer,Paper
} from '@mui/material';
const useStyles = makeStyles({
    table: {
      minWidth: 650
    },
    sticky: {
      position: "sticky",
      left: 0,
      background: "white",
      boxShadow: "5px 2px 5px grey",
      borderRight: "2px solid black"
    }
  });
const Passengers = () => {
  
const classes = useStyles();
  const [passengersList, setPassengersList] = useState([]);
  const [passengersCount, setPassengersCount] = useState(0);
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10
  });

  useEffect(() => {
    const getData = async () => {
      const url = `https://api.coinranking.com/v2/coins?offset=${controller.page*controller.rowsPerPage}&limit=${controller.rowsPerPage}`
      console.log(url)
      const requestOptions = {headers:[]}
      requestOptions.headers['Content-Type'] = 'application/json';
      requestOptions.headers['x-access-token'] = 'coinranking9d91bdc38d4eecf211a2bfa4a326a723ecdc251dad450fa0';
      try {
        const response = await fetch(url, requestOptions);
        if (response.status ==200) {
          const result = await response.json();
          console.log(result);
          setPassengersList(result.data.coins);
          setPassengersCount(result.data.stats.totalCoins);
        } else {
          throw new Error('Request failed')
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [controller]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  return (
    <Card>
<TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.sticky}>
              Image
            </TableCell>
            <TableCell>
              Name
            </TableCell>
            <TableCell>
              Symbol
            </TableCell>
            <TableCell>
              MarketCap
            </TableCell>
            <TableCell>
              Price
            </TableCell>
            <TableCell>
              Change
            </TableCell>
            <TableCell>
              Stats
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {passengersList.map((passenger) => (
            <TableRow key={passenger.uuid}>
                <TableCell className={classes.sticky}>
                <img src={passenger.iconUrl} className="crypto-icon"/>
              </TableCell>
              <TableCell>
                {passenger.name}
              </TableCell>
              <TableCell>
                {passenger.symbol}
              </TableCell>
              <TableCell>
                {passenger.marketCap}
              </TableCell>
              <TableCell>
                {passenger.price}
              </TableCell>
              <TableCell>
                {passenger.change}
              </TableCell>
              <TableCell>
                {passenger.sparkline?<Sparklines data={passenger.sparkline}>
                <SparklinesLine color="blue" />
                </Sparklines>:""}
            </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        onPageChange={handlePageChange}
        page={controller.page}
        count={passengersCount}
        rowsPerPage={controller.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </TableContainer>
    </Card>
  )
}

export default Passengers;