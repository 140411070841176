import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import AllStats from '../component/AllStats';
import CryptoNews from '../component/CryptoNews';
import CoinStatistics from '../component/CoinStatistics';
import ComparisonTable from '../component/ComparisonTableAll';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Ads from '../component/Advertise';
import Highlight from '../component/Highlight';
const Cryptocurrencies = () => {
  const fullWidth = useSelector((state) => state.toggleWidth.value);
    return (
        <>
         <Highlight/>
         <Container fluid style={{ marginTop: '20px' }}>
          <Row>
            <Col md={12} lg={ 12 } style={{ marginBottom: '20px' }}>
              <ComparisonTable simplified={ true }/>
            </Col>
          </Row>
          </Container>
        <CoinStatistics/>
        <Ads/>
        </>
    )
  }
  
  export default Cryptocurrencies