import axios from "axios";

const API_URL = "https://api-nuvacompare.sparklerpro.com/api/auth/";
const API_URL_ENDPOINT = 'https://api-nuvacompare.sparklerpro.com/api/';

//const API_URL = "http://localhost:8080/api/auth/";
//  const API_URL_ENDPOINT = 'http://localhost:8080/api/';

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "signin", {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }

  createads(fields) {
    return axios
      .post(API_URL_ENDPOINT + "adrequest",fields)
      .then(response => {
         console.log(response.data);
        return response.data;
      });
  }
  generateInvoice(fields) {
    return axios
      .post(API_URL_ENDPOINT + "generateinvoice",fields)
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }
  
}

export default new AuthService();
