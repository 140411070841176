import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { cryptoApi } from '../services/cryptoApi';
import { cryptoNewsApi } from '../services/cryptoNewsApi';
import { coingeckoApi } from '../services/coingeckoApi';
import { coinrankApi } from '../services/coinrankApi';
import fullWidth from '../services/fullWidth';

export default configureStore({
    reducer: {
        [cryptoApi.reducerPath]: cryptoApi.reducer,
        [cryptoNewsApi.reducerPath]: cryptoNewsApi.reducer,
        [coingeckoApi.reducerPath]: coingeckoApi.reducer,
        [coinrankApi.reducerPath]: coinrankApi.reducer,
        toggleWidth: fullWidth,
    },
    //Adding the api middle enables caching, invalidation, polling
    // and other useful features of 'rtk-query'
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(cryptoApi.middleware).concat(cryptoNewsApi.middleware).concat(coingeckoApi.middleware).concat(coinrankApi.middleware),
});