import React, { useContext } from 'react';
import { ExchangeContext } from '../contexts/ExchangeContext';
import { useGetExchangeCoinQuery} from '../services/cryptoApi';


const ExchangeCoin = (exchangeId) => {
    console.log(exchangeId.exchange)
    const { setDisplayCoin } = useContext(ExchangeContext);
    const {data, isFetching}  = useGetExchangeCoinQuery(exchangeId.exchange);
    //filtering Exchanges
    
    const toDecimals = (cryptoPrice) => ( parseFloat(cryptoPrice).toFixed(4));
    return (
        <div className="crypto-row" style={{display:"block"}}>
            <div className="coin-price text-left" style={{ minWidth: '12rem'}}>
                { data?.data?.coins?.map((exchange) => (
                    <div className='d-flex coin-row'>
                    <div className="">
                      <img src={ exchange.iconUrl } alt={ exchange.name } className="crypto-icon-exchange"/>
                      {exchange.name}({exchange.symbol})
                    </div>
                    <div className="coin-price" key={ exchange.uuid }>
                        ${ toDecimals( exchange.price) }
                    </div>
                    </div>
                ))}
              </div>  
        </div>
    )
}

export default ExchangeCoin