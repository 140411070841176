import React, { useContext, useState,Component } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FormThemeProvider, Radio } from 'react-form-component'
import ReactDOM from 'react-dom'
import Grid from '@mui/material/Grid';
import AuthService from "../services/auth.service";
import {useParams} from 'react-router-dom';

import Form, {
  Input,
  Text,
  ImageUpload,
  SubmitButton,
  FormActions,
  MultiSelect,
  Select,
  TextArea,
  FormButton,
  inlineLabel
} from 'react-form-component'

const GenerateInvoice = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const params = useParams();

  const handleSubmit = async (fields) => {
   console.log(fields)
    try {
      AuthService.generateInvoice(fields).then(
        () => {
          setIsSubmitted(true);
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

   

    return (
        <div className='container'>
            <div className='col-md-12'>
                <h3 style={{"text-align":"left"}}>Create New Invoice</h3>
                <p className='invoice-detail'>Details</p>
           {isSubmitted ? ( 
              <div>
              <p>Invoice generated successfully</p>
            </div>
          ) : ( <FormThemeProvider>
                <Form className='invoice-form'
  fields={[
    'packageName',
    'amount',
    'amountType',
    'btc',
  ]}

>
<Row>
<Input
    name="userId"
    type="hidden"
    initialValue={params.id}
  />
<Col md={6}>
<Input
    label="Package Name"
    name="packageName"
  />
  </Col>
  <Col md={6}>
  <Input
    label="Amount(USD)"
    name="amount"
  />
  </Col>
  </Row>
  <Row>
  <Col md={6}>
  
  <Select
      name='amountType'
      label='Amount Type'
      placeholder='Select Payment Type'
      required
      options={['BTC']}
    />
  </Col>
  <Col md={6}>
   <Input
    label="Btc"
    name="btc"
  />
  </Col>
  <Col md={6}>
   <Input
    label="Time Period"
    name="timePeriod"
  />
  </Col>
  </Row>
  
  <FormActions align="left" className="form-action">
    <SubmitButton reset>
      Reset
    </SubmitButton>
    <SubmitButton onClick={handleSubmit}>
    Create Invoice
    </SubmitButton>
  </FormActions>
            </Form>
  </FormThemeProvider>
      )}

            </div>
        </div>
    )
  }

  
  export default GenerateInvoice