import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import CryptoNews from '../component/CryptoNews';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Ads from '../component/Advertise';

const Home = () => {
  const fullWidth = useSelector((state) => state.toggleWidth.value);
    return (
        <>
        <h3 className='subheading'>Crypto news</h3>
         <CryptoNews/>
         <Ads/>
        </>
    )
  }
  
  export default Home