import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import AllStats from '../component/AllStats';
import CoinList from '../component/CommoditiesList';
import CoinListTwo from '../component/CommoditiesListEnergy';
import Agricultural from '../component/CommoditiesListAgricultural';
import Industrial from '../component/CommoditiesListIndustrial';
import Livestock from '../component/CommoditiesListLivestock';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs'
import Ads from '../component/Advertise';
const Commodities = () => {
    return (
        <>
        <div className='container-fluid container'>
        <h3 className='subheading'> Commodities</h3>
            <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="mb-3 mtab"
        >
        <Tab eventKey="home" title="Metal" tabClassName='btn btn-primary'>
            <CoinList/>
        </Tab>
        <Tab eventKey="profile" title="Energy" tabClassName='btn btn-primary'>
        <CoinListTwo/>
        </Tab>
        <Tab eventKey="contact" title="Agricultural" tabClassName='btn btn-primary'>
          <Agricultural/>
        </Tab>
        <Tab eventKey="industrial" title="Industrial" className='btn'>
           <Industrial/>
        </Tab>
          <Tab eventKey="livestock" title="Livestock" className='btn'>
            <Livestock/>
          </Tab>
        </Tabs>
        </div>
        <Ads/>
        </>
    )
  }
  
  export default Commodities