import React ,{ createContext } from 'react';
import { useGetCryptosQuery } from '../services/cryptoApi';
export const CryptoContext = createContext(null);

export const CryptoProvider = ({ children }) => {
    const { data, isFetching } = useGetCryptosQuery();
    const globalStats = data?.data?.stats;
    const topCoins = data?.data?.coins;
    
    return (
        <CryptoContext.Provider value={{ globalStats, topCoins, isFetching }}>
            { children }
        </CryptoContext.Provider>
    )
}