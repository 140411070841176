import React, { Component,useContext } from 'react';
import {  BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './component/Header';
import Footer from './component/Footer';
import { ExchangeProvider } from './contexts/ExchangeContext';
import { CryptoProvider } from './contexts/CryptoContext';
import Home from './pages/Home';
import Cryptocurrencies from './pages/Cryptocurrencies';
import Categories from './pages/Categories';
import Feeds from './pages/Feeds';
import GlobalChart from './pages/GlobalChart';
import './App.css';
import './Highlight.css'
import AuthService from "./services/auth.service";
import EventBus from "./common/EventBus";

import Exchanges from './pages/Exchanges';
import CreateAds from './pages/CreateAds';
import GenerateInvoice from './pages/GenerateInvoice';
import Coins from './pages/Coins';
import Admin from './pages/Admin';
import Invoice from './pages/Invoice';
import InvoiceDetail from './pages/InvoiceDetail'
import Login from './pages/Login';

import AdsManagement from './pages/AdsManagement';
import PaymentManagement from './pages/PaymentManagement';
import Commodities from './pages/Commodities'
//import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

class  App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
    
    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }
  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;
  return (
    <div className="App">
      <Router>
      <CryptoProvider>
        <ExchangeProvider>
          <Header/>
          <Routes> {/* The Switch decides which component to show based on the current URL.*/}
          <Route exact path="/" element={<Home/>}/>
          <Route exact path='/cryptocurrencies' element={<Cryptocurrencies/>}></Route>
          <Route exact path='/categories' element={<Categories/>}></Route>
          <Route exact path='/feeds' element={<Feeds/>}></Route>
          <Route exact path='/exchanges' element={<Exchanges/>}></Route>
          <Route exact path='/globalchart' element={<GlobalChart/>}></Route>
          <Route exact path='/createads/:id' element={<CreateAds/>}></Route>
          <Route exact path='/generateinvoice/:id' element={<GenerateInvoice/>}></Route>
          <Route exact path='/invoicedetail/:id' element={<InvoiceDetail/>}></Route>
          <Route exact path='/adsmanagaement' element={<AdsManagement/>}></Route>
          <Route exact path='/coins' element={<Coins/>}></Route>
          <Route exact path='/commodities' element={<Commodities/>}></Route>
          <Route exact path='/admin' element={<Admin/>}></Route>
          <Route exact path='/invoice' element={<Invoice/>}></Route>
          <Route exact path='/login' element={<Login/>}></Route>
          <Route exact path='/payment/:id' element={<PaymentManagement/>}></Route>
          </Routes>
        </ExchangeProvider>
      </CryptoProvider>
      <Footer/>    
      </Router>
    </div>
   
  );
  }
}

export default App;
