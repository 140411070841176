import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import BarChart from '../component/charts/BarChart';
import PieChart from '../component/charts/PieChart'
import DoughnutChart from '../component/charts/DoughnutChart';
 import PolarAreaChart from '../component/charts/PolarAreaChart';
 import LineChart from '../component/charts/LineChart';
 import AreaChart from '../component/charts/AreaChart';
// import Spacer from '../component/Spacer';


const Dashboard = () => {
    const theme = useTheme();
    
    return (
        <React.Fragment>
          
            <Box 
                sx={{ 
                    backgroundColor: theme.palette.background.default, 
                    minHeight: '100%', 
                    py: 8
                }}
            >
                <Container maxWidth={false}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <BarChart />

                        </Grid>
                        <Grid item md={6} xs={12}>
                            <PieChart />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <PolarAreaChart />
                        </Grid>

                         <Grid item md={4} xs={12}>
                            <DoughnutChart />
                        </Grid>
                       <Grid item md={8} xs={12}>
                            <LineChart />
                        </Grid>

                        <Grid item xs={12}>
                            <AreaChart />
                        </Grid> 
                        
                    </Grid>
                </Container>
            </Box>
           
        </React.Fragment>
    );
};

export default Dashboard;