import React, { useContext, useState } from 'react';
import { ExchangeContext } from '../contexts/ExchangeContext';
import { Row, Col, Card, Modal, Button } from 'react-bootstrap';
import { useGetCoinQuery, useGetCoinHistoryQuery } from '../services/cryptoApi';
import logo from './logowhite.png';
import millify from 'millify';


import LineChart from './LineChart';

const CoinStatistics = () => {
  const { displayCoin, showModel, setShowModel } = useContext(ExchangeContext);
  const { data, isFetching } = useGetCoinQuery(displayCoin);
  const coinDetails = data?.data?.coin;

  const { data:coinHistory, isFetching:isCoinHistoryFetching } = useGetCoinHistoryQuery(displayCoin);
  
  const toDecimals = (cryptoPrice) => ( parseFloat(cryptoPrice).toFixed(2));
  // const [show, setShow] = useState(true);
  
  //setShow(true);
 const handleClose = () => setShowModel(false);

  return (
    <>
      <div className="statistics-wrapper">
        { coinDetails &&
          <>
          <Modal show={showModel}>
          <Modal.Header closeButton  onClick={handleClose}>
            <Modal.Title className='mtitle'>
              <p className="text-center h3 mb-4"><img style={{ width: '30px' }} src={ coinDetails.iconUrl } alt={ coinDetails.name } /> { coinDetails.name } Statistics</p>  
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
                    
              <div className="statistics">
                <div className="token-value-statistics">
                  
                  <p className="statistics-title">Value Statistics</p>
                  
                  <div className="token-value-item">
                    <p>Price</p>
                    <h5>${ toDecimals(coinDetails.price) }</h5>
                  </div> 
                  <div className="token-value-item">
                    <p>24h Change</p>
                    <h5>{ coinDetails.change}%</h5>
                  </div>
                  <div className="token-value-item">
                    <p>Market Capitalization</p>
                    <h5>$ { millify(coinDetails.marketCap)}</h5>
                  </div>
                  <div className="token-value-item">
                    <p>All Time High</p>
                    <h5>$ { toDecimals(coinDetails.allTimeHigh.price) }</h5>
                  </div>
                </div>
                <div className="other-statistics">
                  
                      <p className="statistics-title">Other Statistics</p>
                    
                    <div className="token-value-item">
                      <p>Total Supply</p>
                      <h5>{ coinDetails.supply.total }</h5>
                    </div>
                    <div className="token-value-item">
                      <p>Circulating Supply</p>
                      <h5>{ coinDetails.supply.circulating }</h5>
                    </div>
                    <div className="token-value-item">
                      <p>No. of Exchanges</p>
                      <h5>{ coinDetails.numberOfExchanges }</h5>
                    </div>
                    <div className="token-value-item">
                      <p>No of Markets</p>
                      <h5>{ coinDetails.numberOfMarkets }</h5>
                    </div>
                </div>
                <div className="chart">
                  <LineChart coinHistory={coinHistory} />
                </div>
              </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Close Modal</Button>
          </Modal.Footer>
          </Modal>
          </>
          }
          
        </div>
    </>
  )
}

export default CoinStatistics