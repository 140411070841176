import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

const Loader = () => {
  const newLocal = <FontAwesomeIcon icon={faSync} className="fa-spin" />;
  return (
    <>
        {newLocal}
    </>
  )
}

export default Loader