import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const cryptoNewsHeaders = {
};

const createParameters = (searchText) => ({ 
    q: searchText, freshness: 'Day', textFormat: 'Raw', safeSearch: 'Off'
});
console.log(cryptoNewsHeaders);

const createRequest = (url, searchText, count) => ({ url, method: 'GET', headers: cryptoNewsHeaders, params: createParameters(searchText, count) });

export const cryptoNewsApi = createApi({
    reducerPath: 'cryptoNewsApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://min-api.cryptocompare.com/data/v2/' }),
    endpoints: (builder) => ({
        getCryptoNews: builder.query({
            query: (searchText, count) => createRequest('news/?lang=EN&apiKey=cb61428f173bd9dfe46ab55e0eed23fa04c30251eb044150e3e5731135e975fa', searchText, count)
        }),
    }),
});

export const { useGetCryptoNewsQuery } = cryptoNewsApi;