import React, { useEffect,useState,useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FormThemeProvider,Radio } from 'react-form-component'
import ReactDOM from 'react-dom'
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
import {useParams} from 'react-router-dom';
import countryList from 'react-select-country-list'

import Form, {
  Input,
  Text,
  ImageUpload,
  SubmitButton,
  FormActions,
  MultiSelect,
  Select,
  TextArea,
  FormButton,
  inlineLabel
} from 'react-form-component'

const CreateAds = () => {
  const [adsDetail, setAdsDetail] = useState([]);
  const options = useMemo(() => countryList().getLabels(), [])
  const params = useParams();

  useEffect(() => {
    const getData = async () => {
      const url = `adsdetail/${params.id}`
      console.log(url)
      try {
        const response = await UserService.getAdsDetail(url);
        if (response.status ==200) {
          setAdsDetail(response.data.ads);
        } else {
          throw new Error('Request failed')
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  },[]);

  const handleSubmit = async (fields) => {
    console.log(fields)
     try {
       AuthService.createads(fields).then(
         () => {
            //console.log("request")
           window.location.reload();
         },
         error => {
           const resMessage =
             (error.response &&
               error.response.data &&
               error.response.data.message) ||
             error.message ||
             error.toString();
 
           this.setState({
             loading: false,
             message: resMessage
           });
         }
       );
     } catch (err) {
       console.log(err);
     }
   };
    return (
        <>
        <div className='container row'>
            <div className='col-md-5 preview'>
               <h3>Preview</h3>
               <img src={adsDetail?"https://api-nuvacompare.sparklerpro.com/uploads/"+adsDetail.image:""}/>
            </div>
            <div className='col-md-7'>
                <h3>Ads Details</h3>
                <p>Complete you ads details, to engage with you audiencs.</p>
                <hr/>
                <FormThemeProvider>
            <Form
  fields={[
    'firstName',
    'lastName',
    'jobTitle',
    'workEmail',
    'companyName',
    'image',
    'website',
    'budget',
    'country',
    'objective',
    'campaignPeriod',
    'status',
    'adId',
    'endDate'
  ]}
  // mandatory={[
  //   'firstName',
  //   'lastName',
  //   'jobTitle',
  //   'workEmail',
  //   'companyName',
  //   'image',
  //   'website',
  //   'budget',
  //   'country',
  //   'objective',
  //   'campaignPeriod'
  // ]}
>
{adsDetail ? (<><Row>
<Col md={6}>

<Input
    label="First Name"
    name="firstName"
    initialValue={adsDetail.firstname}
  />
  </Col>
  <Col md={6}>
  <Input
    label="Last Name"
    name="lastName"
    initialValue={adsDetail.lastname}
  />
  </Col>
  </Row>
  <Row>
  <Col md={6}>
  <Input
    label="Job title"
    name="jobTitle"
    initialValue={adsDetail.jobtitle}
  />
  </Col>
  <Col md={6}>
   <Input
    label="Work email address"
    name="workEmail"
    initialValue={adsDetail.workemail}
  />
  </Col>
  </Row>
  <Row>
  <Col md={6}>
  <Input
    label="Company website URL"
    name="website"
    initialValue={adsDetail.website}
  />
  </Col>
  <Col md={6}>
   <Input
    label="Company name"
    name="companyName"
    initialValue={adsDetail.companyname}
  />
  </Col>
  </Row>
  <Row>
  <Col md={6}>
  <Radio
    label="What is your advertising budget?"
    name="budget"
    initialValue={adsDetail.budget}
    options={['Less than $5,000', '$5,000 - $20,000', '$20,000 - $40,000','$40,000 - $50,000','Others']}
  />
  </Col>
  <Col md={6}>
  <MultiSelect
    label="Which countries are you planning on targeting?"
    name="country"
    initialValue={adsDetail.country?JSON.parse(adsDetail.country):[]}
    options={options}
  />
  </Col>
  </Row>
  <Row>
  <TextArea
    label="1) What is your marketing objective?"
    name="objective"
    initialValue={adsDetail.objective}
  />
  </Row>
  <Row>
  <Col md={6}>
  <Input
    label="When is your campaign period Start"
    name="campaignPeriod"
    type="date"
    initialValue={adsDetail.campaignperiod}
  />
  </Col>
  <Col md={6}>
  <Input
    label="When is your campaign period End"
    name="campaignPeriod"
    type="date"
    initialValue={adsDetail.endDate}
  />
  </Col>
  </Row>
  <Row>
  <Select
    className=""
    help="status"
    label="Status"
    name="status"
    initialValue={adsDetail.status}
    options={[
      {
        label: 'Requested',
        value: 'requested'
      },
      {
        label: 'Pending',
        value: 'pending'
      },
      {
        label: 'Publish',
        value: 'publish'
      },
      {
        label: 'Rejected',
        value: 'rejected'
      }
    ]}
  />
  </Row>
  <Row>
  <ImageUpload
    inlineLabel
    label="Featured image"
    name="image"
  />
  <Input
    name="adId"
    type="hidden"
    initialValue={adsDetail.id}
  />
  </Row>
  </>
):"no content"
}
  
  <FormActions align="left">
    <SubmitButton reset>
      Reset
    </SubmitButton>
    <SubmitButton onClick={handleSubmit}>
      Update
    </SubmitButton>
  </FormActions>
            </Form>
  </FormThemeProvider>

            </div>
        </div>
        </>
    )
  }
  
  export default CreateAds