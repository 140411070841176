import React,{ useState, useEffect } from 'react';
import { Sparklines,SparklinesLine } from 'react-sparklines';
import { makeStyles } from "@material-ui/core/styles";
import UserService from "../services/user.service";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableContainer,Paper
} from '@mui/material';
const useStyles = makeStyles({
    table: {
      minWidth: 650
    },
    sticky: {
     
    }
  });
const Passengers = () => {
  
const classes = useStyles();
  const [passengersList, setPassengersList] = useState([]);
  const [passengersCount, setPassengersCount] = useState(0);
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10
  });

  useEffect(() => {
    const getData = async () => {
      const url = `invoicelist?offset=${controller.page*controller.rowsPerPage}&limit=${controller.rowsPerPage}&status=pending`
      console.log(url)
      try {
        const response = await UserService.getInvoiceList(url);
        if (response.status ==200) {
            console.log(response);
          setPassengersList(response.data.users);
          setPassengersCount(response.data.count);
        } else {
          throw new Error('Request failed')
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [controller]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  return (
    <Card>
<TableContainer component={Paper}>
      <Table>
        <TableHead className='thead'>
          <TableRow>
            <TableCell className={classes.sticky}>
              Invoice No
            </TableCell>
            <TableCell>
              Package
            </TableCell>
            <TableCell>
              Amount
            </TableCell>
            <TableCell>
              PaymentMode
            </TableCell>
            <TableCell>
              Mode Amount
            </TableCell>
            <TableCell>
              User
            </TableCell>
            <TableCell>
              Status
            </TableCell>
            <TableCell>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {passengersList.map((passenger) => (
            <TableRow key={passenger.id}>
              <TableCell>
                {passenger.id}
              </TableCell>
              <TableCell>
                {passenger.packagename}
              </TableCell>
              <TableCell>
                ${passenger.amount}
              </TableCell>
              <TableCell>
                {passenger.amountType}
              </TableCell>
              <TableCell>
                ${passenger.btc}
              </TableCell>
              <TableCell>
                {passenger.user.firstname}
              </TableCell>
              <TableCell>
              <span className= {passenger.status}>{passenger.status}</span>
              </TableCell>
              <TableCell>
               <a href={"/invoicedetail/"+passenger.id}>view</a>|<a href={"/payment/"+passenger.id}>UserInvoice</a>
            </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        onPageChange={handlePageChange}
        page={controller.page}
        count={passengersCount}
        rowsPerPage={controller.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </TableContainer>
    </Card>
  )
}

export default Passengers;