import { createContext, useState } from 'react';
import * as React from 'react';

export const ExchangeContext = createContext(null);

export const ExchangeProvider = ({ children }) => {
    const [displayCoin, setDisplayCoin] = useState('Qwsogvtv82FCd'); // this is for top statistics
    const [showModel, setShowModel] = useState(false);
    
    return (<ExchangeContext.Provider value={{ displayCoin, setDisplayCoin, showModel, setShowModel}}>
        { children }
    </ExchangeContext.Provider>)
}