import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: false,
}

export const fullWidthSlice = createSlice({
    name: 'fullWidth',
    initialState,
    reducers: {
        toggleWidth: (state) => {
            state.value = !state.value;
        }
    }
})

export const { toggleWidth } = fullWidthSlice.actions;

export default fullWidthSlice.reducer;