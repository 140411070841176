import React, { useContext } from 'react';
import { Container, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsDownToLine } from '@fortawesome/free-solid-svg-icons';

import Loader from './Loader';
import CoinPrice from './CoinPrice';

import { toggleWidth } from '../services/fullWidth';
import { useSelector, useDispatch } from 'react-redux';
import { useGetExchangesQuery,useGetCryptosQuery } from '../services/cryptoApi';
import { ThemeContext } from '../contexts/ThemeContext';

const ComparisonTable = (simplified) => {
    const count = simplified ? 10 : 50;
    const { coinCount, loadMoreCoin } = useContext(ThemeContext);
    const fullWidth = useSelector((state) => state.toggleWidth.value);
    const dispatch = useDispatch();

    const {data, isFetching}  = useGetExchangesQuery('Qwsogvtv82FCd');

    const {data:coins, isCoinFetching} = useGetCryptosQuery(1000);

    const  allcoin=  coins?.data?.coins;
    console.log(allcoin);
    //filtering Exchanges
    const showExchanges = ['Binance', 'Coinbase Pro', 'FTX', 'Kraken', 'Kucoin', 'Huobi Global', 'Gate.io','Crypto.com', 'Bitfinex', 'Bitstamp', 'OKX', 'BitMart','Coinone','WazirX','LiteBit','Gemini','Bitforex'];
    const filteredExchangeList = data?.data?.exchanges?.filter(val => (showExchanges.includes(val.name)));
    

    const coinList = {
        Bitcoin: { uuid: 'Qwsogvtv82FCd', name: 'Bitcoin', ticker: 'BTC', iconUrl: 'https://cdn.coinranking.com/bOabBYkcX/bitcoin_btc.svg' } ,
        Ethereum: { uuid: 'razxDUgYGNAdQ', name: 'Ethereum', ticker: 'ETH', iconUrl: 'https://cdn.coinranking.com/rk4RKHOuW/eth.svg' },
        Tether: { uuid: 'HIVsRcGKkPFtW', name: 'Tether USD', ticker: 'USDT', iconUrl: 'https://cdn.coinranking.com/mgHqwlCLj/usdt.svg' },
        BinanceCoin: { uuid: 'WcwrkfNI4FUAe', name: 'Binance Coin', ticker: 'BNB', iconUrl: 'https://cdn.coinranking.com/B1N19L_dZ/bnb.svg'},
        Xrp: { uuid: '-l8Mn2pVlRs-p', name: 'Xrp', ticker: 'XRP', iconUrl: 'https://cdn.coinranking.com/B1oPuTyfX/xrp.svg'},
        Hex: { uuid: '9K7m6ufraZ6gh', name: 'Hex', ticker: 'HEX', iconUrl: 'https://cdn.coinranking.com/iseN4Am58/hex-vector.svg'},
        Cardano: { uuid: 'qzawljRxB5bYu', name: 'Cardano', ticker: 'ADA', iconUrl: 'https://cdn.coinranking.com/ryY28nXhW/ada.svg'},
        Terra: { uuid: 'AaQUAs2Mc', name: 'Terra', ticker: 'LUNA', iconUrl: 'https://cdn.coinranking.com/F-PJdF8Um/LUNA.svg'},
        Solana: { uuid: 'zNZHO_Sjf', name: 'Solana', ticker: 'SOL', iconUrl: 'https://cdn.coinranking.com/yvUG4Qex5/solana.svg'},
        Polkadot: { uuid: '25W7FG7om', name: 'Polkadot', ticker: 'DOT', iconUrl: 'https://cdn.coinranking.com/RsljYqnbu/polkadot.svg'},
        Polygon: { uuid: 'uW2tk-ILY0ii', name: 'Polygon', ticker: 'MATIC', iconUrl: 'https://cdn.coinranking.com/HAf8rW3kx/polygon-matic-rebrand.png'},
        }
    

  return (
      <div >
        <Card className="comparisonTable">
            <Container fluid>
                { filteredExchangeList ? 
                    <>
                        <div className="exchange-row">
                            <div style={{ minWidth: '2rem'}}></div>
                            <div className="coin-price text-left" style={{ minWidth: '12rem'}}>
                                &nbsp;
                            </div>
                            { filteredExchangeList?.map((exchange) => (
                                <div key={ exchange.name } className="text-center exchange-name">
                                    <img src={ exchange.iconUrl } alt={ exchange.name } className="exchange-logo" />
                                    <p className="exchange-name">{ exchange.name }</p>
                                </div>
                            )) }
                        </div>
                        { coinCount == 5 ? <>
                        <CoinPrice coin={ coinList.Bitcoin } />
                        <CoinPrice coin={ coinList.Ethereum } />
                        </>
                        :''}
                        { coinCount == 10 ? <>
                        <CoinPrice coin={ coinList.Bitcoin } />
                        <CoinPrice coin={ coinList.Ethereum } />
                        <CoinPrice coin={ coinList.Solana } />
                        <CoinPrice coin={ coinList.BinanceCoin } />
                        </>
                        :''}
                        { coinCount == 15 ? <>
                        <CoinPrice coin={ coinList.Bitcoin } />
                        <CoinPrice coin={ coinList.Ethereum } />
                        <CoinPrice coin={ coinList.Solana } />
                        <CoinPrice coin={ coinList.BinanceCoin } />
                        <CoinPrice coin={ coinList.Xrp } />
                        <CoinPrice coin={ coinList.Cardano } />
                        </>
                        :''}
                        { coinCount >= 20 ? <>
                        <CoinPrice coin={ coinList.Bitcoin } />
                        <CoinPrice coin={ coinList.Ethereum } />
                        <CoinPrice coin={ coinList.Solana } />
                        <CoinPrice coin={ coinList.BinanceCoin } />
                        <CoinPrice coin={ coinList.Xrp } />
                        <CoinPrice coin={ coinList.Cardano } />
                        <CoinPrice coin={ coinList.Polkadot } />
                        <CoinPrice coin={ coinList.Polygon } />
                        </>
                        :''}
                        
                        {/*<CoinPrice coin={ coinList.Solana } />
                        <CoinPrice coin={ coinList.BinanceCoin } />
                        <CoinPrice coin={ coinList.Xrp } />
                        <CoinPrice coin={ coinList.Hex } />
                        <CoinPrice coin={ coinList.Cardano } />
                        <CoinPrice coin={ coinList.Terra } />
                        <CoinPrice coin={ coinList.Polkadot } />
                        <CoinPrice coin={ coinList.Tether } />
                        <CoinPrice coin={ coinList.Polygon } /> */}
                        <Button onClick={ loadMoreCoin }>Load More</Button>
                    </>
                : <Loader/> }
            </Container>
        </Card>
     </div>
  )
}

export default ComparisonTable