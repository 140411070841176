import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import AllStats from '../component/AllStats';
import CoinList from '../component/CoinList';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Ads from '../component/Advertise';
const Coins = () => {
    return (
        <>
        <div className='container-fluid'>
        <h3 className='subheading'> Coins</h3>
        <CoinList/>
        </div>
        <Ads/>
        </>
    )
  }
  
  export default Coins