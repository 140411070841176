import React, { useContext, useState,useEffect } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
import  Navbar from './Navbar';
import  TopNavbar from './Navbar';
import LoginForm  from './LoginForm';
import AuthService from "../services/auth.service";
import { Row, Col, Card, Modal, Button } from 'react-bootstrap';
import {  useNavigate } from 'react-router-dom';

const Header = () => {
  const { darkTheme, setDarkTheme } = useContext(ThemeContext);
  const [show, setShow] = useState(false);
  const [currentUser, setCurrentUser] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const getData = async () => {
      try {
        const user = AuthService.getCurrentUser();
        setCurrentUser(user);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);
  
  function handleShow() {
    setShow(true);
  }

  function logOut() {
    console.log("logout");
    AuthService.logout();
    setCurrentUser(null);
     navigate("/");
  }
  
  const handleClose = () => setShow(false);
   return (
    <div>
      <div className='heading-section'>
         <div className='container'>
          <span>Insights on the Cryptocurrency Hardware Wallet Global Market to 2031 - by Hardware Component, Security Method, Type and Region | Crypto firms go out</span>
         </div>
      </div>
      <div className='sub-section container'>
        <div>
         <select>
              <option>USD</option>
         </select>
        </div>
        { currentUser?<div>
          <a href="/invoice" className='dasbutton'><Button variant="outline-primary" className='login-button'>Invoices</Button></a>
          <a href="/admin" className='dasbutton'><Button variant="outline-primary" className='login-button'>Dashboard</Button></a>
          <Button variant="outline-primary" className='login-button' onClick={()=>logOut()}>Logout</Button>{' '}
          </div>
          :<div><a href="/login"><Button variant="outline-primary" className='login-button'>Log In</Button></a>{' '}</div>
         }
      </div>
    <div className="top-section">
       <Navbar/>
    </div>
    <Modal show={show} className="loginModal">
     <Modal.Header closeButton  onClick={handleClose}>
       <Modal.Title className='mtitle'>Admin Login
         </Modal.Title>
     </Modal.Header>
     <Modal.Body>
         <LoginForm/>
     </Modal.Body>
     <Modal.Footer>
     </Modal.Footer>
   </Modal>
    </div>
    
  )
}

export default Header