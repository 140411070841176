import React,{ useState, useEffect } from 'react';
import { Sparklines,SparklinesLine } from 'react-sparklines';
import { makeStyles } from "@material-ui/core/styles";
import UserService from "../services/user.service";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableContainer,Paper
} from '@mui/material';
const useStyles = makeStyles({
    table: {
      minWidth: 650
    },
    sticky: {
      position: "sticky",
      left: 0,
      background: "white",
      boxShadow: "5px 2px 5px grey",
      borderRight: "2px solid black"
    }
  });
const Passengers = () => {
  
const classes = useStyles();
  const [passengersList, setPassengersList] = useState([]);
  const [passengersCount, setPassengersCount] = useState(0);
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10
  });

  useEffect(() => {
    const getData = async () => {
      const url = `adlist?offset=${controller.page*controller.rowsPerPage}&limit=${controller.rowsPerPage}`
      console.log(url)
      try {
        const response = await UserService.getAdminBoard(url);
        if (response.status ==200) {
            console.log(response);
          //const result = await response.json();
          setPassengersList(response.data.users);
          setPassengersCount(response.data.count);
        } else {
          throw new Error('Request failed')
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [controller]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  return (
    <Card>
<TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.sticky}>
              Image
            </TableCell>
            <TableCell>
              FirstName
            </TableCell>
            <TableCell>
             companyname
            </TableCell>
            <TableCell>
              website
            </TableCell>
            <TableCell>
              workemail
            </TableCell>
            <TableCell>
              Budget
            </TableCell>
            <TableCell>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {passengersList.map((passenger) => (
            <TableRow key={passenger.id}>
              <TableCell>
                <img src={"https://api-nuvacompare.sparklerpro.com/uploads/"+passenger.image} className="crypto-icon"/>
              </TableCell>
              <TableCell>
                {passenger.firstname}
              </TableCell>
              <TableCell>
                {passenger.companyname}
              </TableCell>
              <TableCell>
                {passenger.website}
              </TableCell>
              <TableCell>
                {passenger.workemail}
              </TableCell>
              <TableCell>
                {passenger.budget}
              </TableCell>
              <TableCell>
               <a href={"/createAds/"+passenger.id}>view</a> | <a href={"/generateinvoice/"+passenger.id}>invoice</a>
            </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        onPageChange={handlePageChange}
        page={controller.page}
        count={passengersCount}
        rowsPerPage={controller.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </TableContainer>
    </Card>
  )
}

export default Passengers;