import React, { useContext, useState,Component,useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FormThemeProvider, Radio } from 'react-form-component'
import ReactDOM from 'react-dom'
import Grid from '@mui/material/Grid';
import AuthService from "../services/auth.service";
import countryList from 'react-select-country-list'

import Form, {
  Input,
  Text,
  ImageUpload,
  SubmitButton,
  FormActions,
  MultiSelect,
  Select,
  TextArea,
  FormButton,
  inlineLabel
} from 'react-form-component'

const CreateAds = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const options = useMemo(() => countryList().getLabels(), [])
  console.log(options);
  const handleSubmit = async (fields) => {
   console.log(fields)
    try {
      AuthService.createads(fields).then(
        () => {
          setIsSubmitted(true);
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

   

    return (
        <div className='container row'>
            <div className='col-md-12'>
                <h3>Get in touch with us</h3>
                <p>Ready to connect with your users? Fill out the form and we will be in touch to discuss the advertisement opportunities.</p>
                <hr/>
           {isSubmitted ? ( 
              <div>
              <p>Thank you for submitting request!</p>
            </div>
          ) : ( <FormThemeProvider>
                <Form
  fields={[
    'firstName',
    'lastName',
    'jobTitle',
    'workEmail',
    'companyName',
    'image',
    'website',
    'budget',
    'country',
    'objective',
    'campaignPeriod',
    'endDate',
  ]}
  // mandatory={[
  //   'firstName',
  //   'lastName',
  //   'jobTitle',
  //   'workEmail',
  //   'companyName',
  //   'image',
  //   'website',
  //   'budget',
  //   'country',
  //   'objective',
  //   'campaignPeriod'
  // ]}
>
<Row>
<Col md={6}>
<Input
    label="First Name"
    name="firstName"
  />
  </Col>
  <Col md={6}>
  <Input
    label="Last Name"
    name="lastName"
  />
  </Col>
  </Row>
  <Row>
  <Col md={6}>
  <Input
    label="Job title"
    name="jobTitle"
  />
  </Col>
  <Col md={6}>
   <Input
    label="Work email address"
    name="workEmail"
  />
  </Col>
  </Row>
  <Row>
  <Col md={6}>
  <Input
    label="Company website URL"
    name="website"
  />
  </Col>
  <Col md={6}>
   <Input
    label="Company name"
    name="companyName"
  />
  </Col>
  </Row>
  <Row>
  <Col md={6}>
  <Radio
    label="What is your advertising budget?"
    name="budget"
    options={['Less than $5,000', '$5,000 - $20,000', '$20,000 - $40,000','$40,000 - $50,000','Others']}
  />
  </Col>
  <Col md={6}>
  <MultiSelect
    label="Which countries are you planning on targeting?"
    name="country"
    options={options}
  />
  </Col>
  </Row>
  <Row>
  <TextArea
    label="1) What is your marketing objective?"
    name="objective"
  />
  </Row>
  <Row>
  <Col md={6}>
  <Input
    label="When is your campaign period Start"
    name="campaignPeriod"
    type="date"
  />
  </Col>
   <Col md={6}>
  <Input
    label="When is your campaign period End"
    name="endDate"
    type="date"
  />
  </Col>
  </Row>
  <Row>
  <ImageUpload
    inlineLabel
    label="Featured image"
    name="image"
  />
  </Row>
  
  <FormActions align="left">
    <SubmitButton reset>
      Reset
    </SubmitButton>
    <SubmitButton onClick={handleSubmit}>
      Submit
    </SubmitButton>
  </FormActions>
            </Form>
  </FormThemeProvider>
      )}

            </div>
        </div>
    )
  }

  
  export default CreateAds