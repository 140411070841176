import React, { useContext } from 'react';
import { ExchangeContext } from '../contexts/ExchangeContext';
import { useGetExchangesQuery } from '../services/cryptoApi';

const CoinPrice = (coin) => {
    const { setDisplayCoin, setShowModel } = useContext(ExchangeContext);
    const {data, isFetching}  = useGetExchangesQuery(coin.coin.uuid);
    //filtering Exchanges
    const showExchanges = ['Binance', 'Coinbase Pro', 'FTX', 'Kraken', 'Kucoin', 'Huobi Global', 'Gate.io','Crypto.com', 'Bitfinex', 'Bitstamp', 'OKX', 'BitMart','Coinone','WazirX','LiteBit','Gemini','Bitforex'];
    const filteredExchangeList = data?.data?.exchanges?.filter(val => (showExchanges.includes(val.name)));
    const toDecimals = (cryptoPrice) => ( parseFloat(cryptoPrice).toFixed(4));
    
    return (
        <div className="crypto-row">
            <div style={{ minWidth: '2rem'}}>&nbsp;</div>
            <div className="coin-price text-left" style={{ minWidth: '12rem'}}>
                <img src={ coin.coin.iconUrl } alt={ coin.coin.name } className="crypto-icon" style={{ cursor: 'pointer'}} onClick={ () => setDisplayCoin(coin.coin.uuid) } />
                <span style={{ cursor: 'pointer'}} onClick={ () => {setDisplayCoin(coin.coin.uuid); setShowModel(true) }}>{ coin.coin.name } ({coin.coin.ticker})</span></div>
                                   
                { filteredExchangeList?.map((exchange) => (
                    <div className="coin-price" key={ exchange.uuid }>
                        ${ toDecimals( exchange.price) }
                    </div>
                ))}
        </div>
    )
}

export default CoinPrice