import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import AllStats from '../component/AllStats';
import ExchangesList from '../component/ExchangesList';
import { Container, Row, Col, Button } from 'react-bootstrap';
import AdsList from '../component/AdsList';
const Cryptocurrencies = () => {
  const fullWidth = useSelector((state) => state.toggleWidth.value);
    return (
        <>
        <AdsList/>
        </>
    )
  }
  
  export default Cryptocurrencies