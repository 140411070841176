import React from 'react';
import { faDisplay } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import adLandscape from '../assets/ad-landscape.png';
import adPotrate from '../assets/ad-potrait.png';
const Ads = () => {
    const fullWidth = useSelector((state) => state.toggleWidth.value);
     return (
    <Container fluid style={{ marginTop: '20px' }}>
        <Row>
            <Col md={12} lg={ fullWidth ? 12 : 9 } style={{ marginBottom: '20px', padding: '20px'}} className="px-5 fdDisplay">
            <img src={adLandscape} alt="Nuva Compare" style={{maxWidth:'100%'}}/>
            </Col>
            <Col md={12} lg={ fullWidth ? 12 : 3} style={{ marginBottom: '20px' }} className="px-5 fdDisplay">
            <img src={adPotrate} alt="Nuva Compare" style={{maxWidth:'100%'}}/>
            </Col>
        </Row>
    </Container>
    )
  }
  
  export default Ads