import React, { useMemo, useContext, useState } from 'react';
import { ExchangeContext } from '../contexts/ExchangeContext';
import { useGetCoinCategoryQuery } from '../services/coingeckoApi';
import logo from './logowhite.png';
import millify from 'millify';


import LineChart from './LineChart';
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableContainer,Paper
} from '@mui/material';
const useStyles = makeStyles({
    table: {
      minWidth: 650
    },
    sticky: {
      position: "sticky",
      left: 0,
      background: "white",
      boxShadow: "5px 2px 5px grey",
      borderRight: "2px solid black"
    }
  });

const Categories = () => {
  const { displayCoin } = useContext(ExchangeContext);
  const { data, isFetching } = useGetCoinCategoryQuery(100,1);
  const [exchanges, setExchanges] = useState([]);
  console.log(data);
  const toDecimals = (cryptoPrice) => ( parseFloat(cryptoPrice).toFixed(2));
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div className="container col-md-12 statistics-wrapper">
      <h3 className='subheading'> Categories</h3>
      {data?
       <Card>
       <TableContainer component={Paper}>
             <Table>
               <TableHead>
                 <TableRow>
                   <TableCell>
                     Name
                   </TableCell>
                   <TableCell>
                    Market Cap
                   </TableCell>
                   <TableCell>
                    Market Cap Change 24h
                   </TableCell>
                   <TableCell>
                     Top Coin
                   </TableCell>
                   <TableCell>
                     Volume 24h
                   </TableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
                 {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((category) => (
                   <TableRow key={category.uuid}>
                     <TableCell>
                       {category.name}
                     </TableCell>
                     <TableCell>
                       {category.market_cap}
                     </TableCell>
                     <TableCell>
                       {category.market_cap_change_24h}
                     </TableCell>
                     <TableCell>
                       <img src={category.top_3_coins}/>
                     </TableCell>
                     <TableCell>
                       {category.volume_24h}
                     </TableCell>
                   </TableRow>
                 ))}
               </TableBody>
             </Table>
             <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
             </TableContainer>
           </Card>:""
      }

      
      </div>
    </>
  )
}

export default Categories