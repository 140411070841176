import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const cryptoApiHeaders = {
}
export const coingeckoApi = createApi({
    reducerPath: 'coingeckoApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://api.coingecko.com/api/v3/' }),
    endpoints: (builder) => ({
        getCoin: builder.query({
            query: (coinUuid) => ({
                url: `coin/${coinUuid}`,
                method: 'GET',
                params: {
                    referenceCurrencyUuid: 'yhjMzLPhuIDl',
                    timePeriod: '24h'
                },
                headers: cryptoApiHeaders
            })
        }),
        getCoinHistory: builder.query({
            query: (coinUuid) => ({
                url: `coin/${coinUuid}/history`,
                method: 'GET',
                params: {
                    referenceCurrencyUuid: 'yhjMzLPhuIDl',
                    timePeriod: '24h',
                },
                headers: cryptoApiHeaders
            })
        }),
        getCryptos: builder.query({
            query: (count) => ({ 
                url: 'coins', 
                method: 'GET', 
                params:  {
                    referenceCurrencyUuid: 'yhjMzLPhuIDl', // yhjMzLPhuIDl is reference to $$$$
                    timePeriod: '24h',
                    tiers: '1',
                    orderBy: 'marketCap',
                    orderDirection: 'desc',
                    limit: count,
                    offset: '0'
                },
                headers: cryptoApiHeaders,
                })
        }),
        getExchanges: builder.query({
            query: (exchangesPerPage,currentPage) => ({
                url: `/exchanges?per_page=${exchangesPerPage}&page=${currentPage}`,
                method: 'GET',
                params: {
                    referenceCurrencyUuid: 'yhjMzLPhuIDl',
                    limit: '50',
                    offset: '0',
                    orderBy: '24hVolume',
                    orderDirection: 'desc'
                },
                headers: cryptoApiHeaders,
            })
        }),
        getCoinCategory: builder.query({
            query: () => ({
                url: `/coins/categories`,
                method: 'GET',
                params: {},
                headers: cryptoApiHeaders,
            })
        }),
        getTrendingCoin: builder.query({
            query: () => ({
                url: `search/trending`,
                method: 'GET',
                params: {},
                headers: cryptoApiHeaders,
            })
        }),
    })
});

export const { 
    useGetCryptosQuery, 
    useGetExchangesQuery, 
    useGetCoinQuery, 
    useGetCoinHistoryQuery,
    useGetCoinCategoryQuery,
    useGetTrendingCoinQuery,
} = coingeckoApi