import React from 'react';
import {Chart as ChartJS} from 'chart.js/auto'
import { Line } from 'react-chartjs-2';
import { isMobile } from 'react-device-detect';


const LineChart = ({ coinHistory }) => {
    const coinPrice = [];
    const coinTimestamp = [];
    for(let i = 0; i < coinHistory?.data?.history?.length; i += 1){
        coinPrice.push(coinHistory.data.history[i].price);
        coinTimestamp.push(new Date(coinHistory.data.history[i].timestamp * 1000).toLocaleString());
    }

    const data = {
        labels: coinTimestamp.reverse(),
        datasets: [
            {
                label: false,
                data: coinPrice.reverse(),
                fill: false,
                backgroundColor: 'transparent',
                borderColor: '#25cf5f',
                lineTension: 0,
                pointRadius: 0,
                borderWidth: 2
            }
        ]
    }

    const options = {
        plugins:{
            legend: {
                display: false
            }
        },
        scales : {
            y: {
                ticks: {
                  display: false,
                },
                grid: {
                    display: false,
                    drawBorder: false
                }
              },
            x: {
            ticks: {
                display: false,
            },
            grid: {
                display: false,
                drawBorder: false
            }
            }
        }
        
    }
  return (
    <>
        <h6>24hr Price Trend</h6>
        <Line data={ data } options={ options } width={ isMobile ? '390px' : '600px' } height={'210px'}/>
    </>
  )
}

export default LineChart