import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://api-nuvacompare.sparklerpro.com/api/test/';
//const API_URL ='http://localhost:8080/api/test/'

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard(url) {
    return axios.get(API_URL + url, { headers: authHeader() });
  }
  getAdsDetail(url) {
    return axios.get(API_URL + url, { headers: authHeader() });
  }
  getInvoiceDetail(url) {
    return axios.get(API_URL + url, { headers: authHeader() });
  }
  getInvoiceList(url) {
    return axios.get(API_URL + url, { headers: authHeader() });
  }
  cancleInvoice(url) {
    return axios.get(API_URL + url, { headers: authHeader() });
  }
  
}

export default new UserService();
