import { useState, createContext } from 'react';
import * as React from 'react';
export const ThemeContext = createContext(null);

export const ThemeProvider = ({ children }) => {
    const [darkTheme, setDarkTheme] = useState('true');
    const [coinCount, setCoinCount] = useState(5);
    const loadMoreCoin = () => { setCoinCount(coinCount + 5)}
    return (
        <ThemeContext.Provider value={{ darkTheme, setDarkTheme, coinCount, loadMoreCoin }}>
            { children }
        </ThemeContext.Provider>
    )
}