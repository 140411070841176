import React, { useContext, useState } from 'react';
import { ExchangeContext } from '../contexts/ExchangeContext';
import { useGetExchangesQuery, useGetCoinHistoryQuery } from '../services/coingeckoApi';
import logo from './logowhite.png';
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableContainer,Paper
} from '@mui/material';

const CoinStatistics = () => {
  const { displayCoin } = useContext(ExchangeContext);
  const { data, isFetching } = useGetExchangesQuery(100,1);
  const [exchanges, setExchanges] = useState([]);
  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  const toDecimals = (cryptoPrice) => ( parseFloat(cryptoPrice).toFixed(2));
  const columns = [  {
    dataField: 'image',
    text: 'Image',
    sort: true,
    formatter: (value, row) => (
      <img style={{width:50}} src={value}/>
    )
  },{
    dataField: 'name',
    text: 'name',
    sort: true
  }, {
    dataField: 'country',
    text: 'country',
  },{
    dataField: 'trade_volume_24h_btc',
    text: '24h Btc',
  },
  {
    dataField: 'trade_volume_24h_btc_normalized',
    text: '24h Normalized',
  },
  {
    dataField: 'trust_score',
    text: 'Trust Score',
  },
  {
    dataField: 'trust_score_rank',
    text: 'Rank',
  }
];
  
  return (
    <>
      <div className="container col-md-12 statistics-wrapper">
      <h3 className='subheading'> Exchanges</h3>
      {data?
       <Card>
       <TableContainer component={Paper}>
             <Table>
               <TableHead>
                 <TableRow>
                   <TableCell>
                     Image
                   </TableCell>
                   <TableCell>
                     Name
                   </TableCell>
                   <TableCell>
                   Country
                   </TableCell>
                   <TableCell>
                   24h Btc
                   </TableCell>
                   <TableCell>
                   24h Normalized
                   </TableCell>
                   <TableCell>
                   Trust Score
                   </TableCell>
                   <TableCell>
                     Rank
                   </TableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
                 {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((category) => (
                   <TableRow key={category.uuid}>
                     <TableCell>
                       <img src={category.image} style={{width:"50px"}}/>
                     </TableCell>
                     <TableCell>
                       {category.name}
                     </TableCell>
                     <TableCell>
                       {category.country}
                     </TableCell>
                     <TableCell>
                       {category.trade_volume_24h_btc}
                     </TableCell>
                     <TableCell>
                    {category.trade_volume_24h_btc_normalized}
                     </TableCell>
                     <TableCell>
                       {category.trust_score}
                     </TableCell>
                     <TableCell>
                       {category.trust_score_rank}
                     </TableCell>
                   </TableRow>
                 ))}
               </TableBody>
             </Table>
             <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
             </TableContainer>
           </Card>:""
      }
      </div>
    </>
  )
}

export default CoinStatistics