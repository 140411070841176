import React, { useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Card } from 'react-bootstrap';
import millify from 'millify';
import Loader from './Loader';
import { CryptoContext } from '../contexts/CryptoContext';

const AllStats = () => {
    
    const { globalStats, isFetching } = useContext(CryptoContext);

  return (
    <div style={{padding: "20px"}}>
       <Row>
            <h3 className='subheading'> Bitcoin Statistics</h3>
            <Col className="col-sm-12 bstats">
            <Col className="col-sm-12 col-md-4">
                <Card>
                    <Card.Body>
                        <Card.Title>Total Exchanges </Card.Title>
                        <Card.Text> 
                           <a href="/exchanges" style={{color:"#212529",textDecoration:"none"}}> { isFetching ? <Loader/> : globalStats.totalExchanges }</a>
                        </Card.Text>
                    </Card.Body>
                </Card>
           </Col>
           <Col className="col-sm-12 col-md-4">
                <Card className='subcard'>
                    <Card.Body>
                        <Card.Title>Total Cryptocurrencies </Card.Title>
                        <Card.Text><a href='/cryptocurrencies' style={{color:"#ffffff!important",textDecoration:"none"}}>{ isFetching ? <Loader/> : globalStats.total }</a>
                        </Card.Text>
                    </Card.Body>
                </Card>
           </Col>
           <Col className="col-sm-12 col-md-4">
                <Card>
                    <Card.Body>
                        <Card.Title>Total Market Cap </Card.Title>
                        <Card.Text>{ isFetching ? <Loader/> : millify(globalStats.totalMarketCap) }
                        </Card.Text>
                    </Card.Body>
                </Card>
           </Col>
           </Col>
           <Col className="col-sm-12 bstats">
           <Col className="col-sm-12 col-md-4">
                 <Card className='subcard'>
                    <Card.Body>
                        <Card.Title>Total 24hr Volume </Card.Title>
                        <Card.Text>{ isFetching ? <Loader/> : millify(globalStats.total24hVolume) }
                        </Card.Text>
                    </Card.Body>
                </Card>
           </Col>
           <Col className="col-sm-12 col-md-4">
                <Card>
                    <Card.Body>
                        <Card.Title>Total Market </Card.Title>
                        <Card.Text>{ isFetching ? <Loader/> : millify(globalStats.totalMarkets) }
                        </Card.Text>
                    </Card.Body>
                </Card>
           </Col>
           <Col className="col-sm-12 col-md-4">
                <Card>
                    <Card.Body>
                        <Card.Title>Total Coins </Card.Title>
                        <Card.Text>{ isFetching ? <Loader/> : millify(globalStats.totalCoins) }
                        </Card.Text>
                    </Card.Body>
                </Card>
           </Col>
           </Col>
       </Row>
        
    </div>
  )
}

export default AllStats