import * as React from 'react';
import { faDisplay } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useGetTrendingCoinQuery } from '../services/coingeckoApi';
import { useGetCryptoNewsQuery } from '../services/cryptoNewsApi';
import { useGetStatsQuery } from '../services/coinrankApi';
import Loader from './Loader';
import PostSlider from './MainSlider';
const Highlight = () => {
    const fullWidth = useSelector((state) => state.toggleWidth.value);
    const {data:coins}  =  useGetTrendingCoinQuery();
    const {data:stats} = useGetStatsQuery();
     console.log(stats);
     return (
<Container fluid style={{ marginTop: '20px' }}>
<div className="row highlight">
    <div  className="col-md-12" style={{textAlign:'left', marginTop:'20px', marginBottom:'20px'}}>
    <h3>Today's Cryptocurrencies prices</h3>
    <span className="widget-49-pro-title">The global cryptocurrency market cap today is ₹88.39 Trillion. In last 24 Hrs the market changed by 2.10%</span>
    </div>
    <div className="col-lg-4">
        <div className="card card-margin">
            
            <div className="card-body pt-0">
                <div className="widget-49">
                    <div className="widget-49-title-wrapper">
                        <div className="widget-49-date-primary">
                        <img src="https://s2.coinmarketcap.com/static/cloud/img/TrendingIcon.png"/>
                        <div className="widget-49-meeting-info">
                            <span className="widget-49-pro-title">Trending</span>
                        </div>
                        </div>
                        <a href="">
                            <span>More</span> 
                            
                        </a>
                    </div>
                    { coins?.coins?coins.coins.slice(0, 3).map((items) => (
                    <div className="widget-item">
                        <div className="widget-item-sub">
                        <span className="rank">{items.item.score}</span>
                        <div  className="coin-wrapper">
                            <img className="coin-logo" src={items.item.small} loading="lazy" alt="undefined logo"/>
                                <div className="name-area ">
                                    <p  className="sc-e225a64a-0 ePTNty">{items.item.name}</p>
                                   
                                    </div>
                         </div>
                         <span className="alias">{items.item.symbol}</span>
                         </div>
                         {/* <div class="price-change">
                            <span class="sc-f1e838b2-0 dRLonq">
                                <span class="icon-Caret-down"></span>21.16%</span>
                          </div> */}
                    </div>
                    )): <Loader/>
                    }
                </div>
            </div>
        </div>
    </div>
    <div className="col-lg-4">
        <div className="card card-margin">
           
            <div className="card-body pt-0">
                <div className="widget-49">
                    <div className="widget-49-title-wrapper">

                        <div className="widget-49-date-primary">
                        <img src="https://s2.coinmarketcap.com/static/cloud/img/AddIcon.png"/>
                        <div className="widget-49-meeting-info">
                            <span className="widget-49-pro-title">Recently Added</span>
                        </div>
                        </div>
                        <a href="">
                            <span>More</span> 
                        </a>
                    </div>
                    {
                    stats?.data?stats.data.newestCoins.map((items) => (    
                    <div class="widget-item">
                        <div class="widget-item-sub">
                        <span class="rank">1</span>
                        <div data-sensors-click="true" class="coin-wrapper">
                            <img class="coin-logo" src={items.iconUrl} loading="lazy" alt="undefined logo"/>
                                <div class="name-area ">
                                    <p   data-sensors-click="true" class="sc-e225a64a-0 ePTNty">{items.name}</p>
                                   
                                    </div>
                         </div>
                         <span class="alias">{items.symbol} </span>
                         </div>
                         <div class="price-change">
                            <span class="sc-f1e838b2-0 dRLonq">
                                <span class="icon-Caret-down"></span>21.16%</span>
                          </div>
                    </div>
                      )): <Loader/>
                    }
                </div>
            </div>
        </div>
    </div>
    <div className="col-lg-4">
        <div className="card card-margin">
            
            <div className="card-body pt-0">
                <div className="widget-49">
                    <div className="widget-49-title-wrapper">
                    <div className="widget-49-date-primary">
                        <img src="https://s2.coinmarketcap.com/static/cloud/img/AddIcon.png"/>
                        <div className="widget-49-meeting-info">
                            <span className="widget-49-pro-title">Top Community Post</span>
                        </div>
                        </div>
                        <a href="/feeds">
                            <span>More</span> 
                        </a>
                    </div>
                    <PostSlider/>
                </div>
            </div>
        </div>
    </div>
 </div>
    </Container>
    )
  }
  
  export default Highlight
