import React, { useState } from 'react'
import { Card } from 'react-bootstrap';

import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useGetCryptoNewsQuery } from '../services/cryptoNewsApi';
import Loader from './Loader';

const demoImage = 'https://coinrevolution.com/wp-content/uploads/2020/06/cryptonews.jpg';

const CryptoNews = () => {
    const [newsFilter, setNewsFilter] = useState('cryptocurrency');
    const { data } = useGetCryptoNewsQuery(newsFilter, 10);

  return (
    <div className="container-fluid row news-wrapper">
        
        { data?.Data ? data.Data.map((news, i) => (
            <div className="news-item col-md-3" key={i}>
                <div className="col-md-12">
                    <a href={ news.url } target="_blank" rel="noreferrer">
                        <img src={ news.imageurl || demoImage } alt="News "/>
                    </a>    
                </div>
                <div className="news-text">
                    <a href={ news.url } target="_blank"  rel="noreferrer">
                        <h6 className="news-title">{ news.title }</h6>
                        <p>{ news.body.substring(0,120)}... </p>
                        <p className="news-meta">
                            <img src={ news.imageurl} alt={ news.title } style={{ marginRight: '5px'}} /> 
                            {news.categories}
                            <span style={{ float: 'right' }}>{ moment(news.published_on).startOf('ss').fromNow()}</span>
                        </p>
                    </a>
                </div>
            </div>
        )) : <Loader/> }        
    </div>
  )
}

export default CryptoNews